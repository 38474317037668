<template>
  <el-container direction="vertical" style="height: 100%" class="container">
    <h1 style="margin-bottom: 50px">查体信息填写表单</h1>
    <el-form
      :model="canshu"
      :rules="rules"
      ref="canshu"
      class="demo-form-inline"
      label-width="200px"
      size="medium"
      style="height: 100%; overflow-y: scroll"
    >
      <el-form-item label="省" style="width: 100%" prop="pro_address">
        <el-select
          v-model="canshu.pro_address"
          placeholder="陕西省"
          style="width: 100%"
        >
          <el-option label="陕西省" value="陕西省"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市" style="width: 100%" prop="city_address">
        <el-select
          v-model="canshu.city_address"
          placeholder="西安市"
          style="width: 100%"
        >
          <el-option label="西安市" value="西安市"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="区" style="width: 100%" prop="area_address">
        <el-select
          v-model="canshu.area_address"
          placeholder="请选择地区"
          style="width: 100%">
          <el-option label="新城区" value="新城区"></el-option>
          <el-option label="碑林区" value="碑林区"></el-option>
          <el-option label="莲湖区" value="莲湖区"></el-option>
          <el-option label="灞桥区" value="灞桥区"></el-option>
          <el-option label="未央区" value="未央区"></el-option>
          <el-option label="雁塔区" value="雁塔区"></el-option>
          <el-option label="阎良区" value="阎良区"></el-option>
          <el-option label="临潼区" value="临潼区"></el-option>
          <el-option label="长安区" value="长安区"></el-option>
          <el-option label="高陵区" value="高陵区"></el-option>
          <el-option label="鄠邑区" value="户县"></el-option>
          <el-option label="蓝田县" value="蓝田县"></el-option>
          <el-option label="周至县" value="周至县"></el-option>
          <el-option label="西咸新区" value="西咸新区"></el-option>
          <el-option label="高新区" value="高新区"></el-option>
          <el-option label="经开区" value="经开区"></el-option>
          <el-option label="曲江新区" value="曲江新区"></el-option>
          <el-option label="浐灞生态区" value="浐灞生态区"></el-option>
          <el-option label="阎良国家航空产业基地" value="阎良国家航空产业基地"></el-option>
          <el-option label="国家民用航天产业基地" value="国家民用航天产业基地"></el-option>
          <el-option label="国际港务区" value="国际港务区"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="查体类型" style="width: 100%" prop="tj_type">
        <el-select
          v-model="canshu.tj_type"
          placeholder="请选择查体类型"
          style="width: 100%"
        >
          <el-option label="入托" value="入托"></el-option>
          <el-option label="入园" value="入园"></el-option>
          <el-option label="入校" value="入校"></el-option>
          <el-option label="社区" value="社区"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="查体点类型" style="width: 100%" prop="points_type">
        <el-select
          v-model="canshu.points_type"
          placeholder="请选择查体点类型"
          style="width: 100%"
        >
          <el-option label="维稳" value="维稳"></el-option>
          <el-option label="提升" value="提升"></el-option>
          <el-option label="新增" value="新增"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="社区名称" style="width: 100%" prop="community_name">
        <el-select
          v-model="canshu.community_name"
          placeholder="请选择社区名称"
          style="width: 100%"
        >
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
          <el-option
            label="xxxx社区服务中心"
            value="xxxx社区服务中心"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学校名称" style="width: 100%" prop="school_name">
        <el-input
          style="width: 100%"
          v-model="canshu.school_name"
          placeholder="请输入学校名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="体检总人数" style="width: 100%" prop="num">
        <el-input
          style="width: 100%"
          v-model="canshu.num"
          placeholder="请输入体检总人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="检出总人数" style="width: 100%" prop="num">
        <el-input
          style="width: 100%"
          v-model="canshu.problem_num"
          placeholder="请输入检出总人数"
        ></el-input>
      </el-form-item>
      <el-form-item class="sift-main" label="体检日期" style="width: 100%" prop="tj_date">
        <el-date-picker
          :append-to-body="false"
          popper-class="sift-data"
          style="width: 100%"
          type="date"
          placeholder="请选择体检日期"
          v-model="canshu.tj_date"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="查体人" style="width: 100%" prop="people">
        <el-input
          style="width: 100%"
          v-model="canshu.people"
          placeholder="请输入查体人"
        ></el-input>
      </el-form-item>
      <el-form-item style="width: 90%; margin-top: 150px;">
        <el-button type="primary" @click="onSubmit()">提交信息</el-button>
      </el-form-item>
    </el-form>
  </el-container>
</template>

<script>
import { Download } from "./download"; //文件下载
import { getTjQrcode } from "../../api/javaApi";
export default {
  components: {
    // saoma,
  },
  data() {
    return {
      download: new Download(),
      base64: "",
      src: "", // 用于存储图片的Base64编码
      dayinanniu: true,
      uploadDisabled: false,
      canshu: {
        tj_type: "",
        points_type: "",
        pro_address: "陕西省",
        city_address: "西安市",
        area_address: "",
        community_name: "",
        school_name: "",
        tj_date: "",
        people: "",
        problem_num: "",
        num: "",
      },
      xianshia: false,
      rules: {
        pro_address: [
          { required: true, message: "请选择省", trigger: "change" },
        ],
        city_address: [
          { required: true, message: "请选择市", trigger: "change" },
        ],
        area_address: [
          { required: true, message: "请选择区", trigger: "change" },
        ],
        tj_type: [
          { required: true, message: "请选择查体类型", trigger: "change" },
        ],
        points_type: [
          { required: true, message: "请选择查体点类型", trigger: "change" },
        ],
        community_name: [
          { required: true, message: "请选择社区名称", trigger: "change" },
        ],
        school_name: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        num: [{ required: true, message: "请输入体检总人数", trigger: "blur" }],
        problem_num: [
          { required: true, message: "请输入检出总人数", trigger: "blur" },
        ],
        tj_date: [
          { required: true, message: "请选择体检日期", trigger: "change" },
        ],
        people: [{ required: true, message: "请填写查体人", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    downLoad() {
      let downloadName = "成长发育宝小程序码.png"; //文件名
      let imgData = this.base64; //base64
      this.download.downloadFile(downloadName, imgData);
    },
    async getCode() {
      let data = {};
      getTjQrcode(data)
        .then((res) => {
          if (res.data.code != 200) {
            this.$message({
              type: "warning",
              message: res.data.message,
              center: true,
            });
          } else {
            this.base64 = res.data.data;
            this.src = "data:image/jpeg;base64," + res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    xiayibu() {
      if (this.canshu.area_address == "" || this.canshu.area_address == null)
        return this.$notify.error({
          title: "警告",
          message: "请选择地区!",
          type: "warning",
          duration: "2000",
        });
      if (this.canshu.address == "" || this.canshu.address == null)
        return this.$notify.error({
          title: "警告",
          message: "请输入测评地点!",
          type: "warning",
          duration: "2000",
        });

      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
      });
      setTimeout((e) => {
        this.uploadDisabled = false;
      }, 1000);
    },
    async onSubmit() {
      if (this.canshu.area_address == "" || this.canshu.area_address == null)
        return this.$notify.error({
          title: "警告",
          message: "请选择地区!",
          type: "warning",
          duration: "2000",
        });
      if (this.canshu.address == "" || this.canshu.address == null)
        return this.$notify.error({
          title: "警告",
          message: "请输入测评地点!",
          type: "warning",
          duration: "2000",
        });
    },
  },
};
</script>

<style scoped lang='scss' scoped>
/* 设置基础样式 */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* 针对桌面和平板设备的样式 */
.container {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  text-align: center;
  height: 100vh;
  font-size: 1vw;
}

/* 下面的CSS是针对移动端设备的样式 */
@media (max-width: 768px) {
  .container {
    max-width: 100%; /* 移动端的最大宽度设置为100% */
    padding: 5px; /* 减小了padding，以适应较小的屏幕 */
  }
}

/deep/ .el-form-item__label {
  font-size: 30px;
  line-height: 80px;
}

/deep/ .el-input--medium .el-input__inner {
  height: 80px;
  line-height: 80px;
}

/deep/ .el-input--medium {
  font-size: 30px;
}

/deep/ .el-input--medium .el-input__icon {
  line-height: 80px;
  margin: 0 20px;
}

/deep/ .el-select .el-input .el-select__caret {
  font-size: 30px;
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 80px;
}

/deep/ .el-button--medium {
  padding: 20px 50px;
  font-size: 30px;
  border-radius: 4px;
}

/deep/ .el-form-item__error {
    color: #F56C6C;
    font-size: 24px;
}

/deep/ .el-form-item {
    margin-bottom: 44px;
}

.el-select-dropdown__item {
  font-size: 30px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-date-editor .el-picker-panel{
  line-height: 60px;
}

/deep/ .el-date-editor .el-picker-panel__icon-btn{
  font-size: 30px;
}

/deep/ .el-date-editor .el-date-picker {
  width: 600px;
}


/deep/ .el-date-editor .el-date-picker__header-label {
    font-size: 30px;
    line-height: 44px;
}

/deep/ .el-date-editor .el-date-table {
    font-size: 30px;
}

/deep/ .el-date-editor .el-date-picker .el-picker-panel__content {
    width: 550px;
}
/deep/ .el-date-editor .el-date-table th {
    padding: 5px 10px;
    text-align: center;
}

/deep/ .el-date-editor .el-date-table td {
    height: 60px;
}

/deep/ .el-date-editor .el-date-table td span {
    width: 50px;
    height: 50px;
    line-height: 50px;
}
</style>
